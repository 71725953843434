import classNames from 'classnames';
import React from 'react';
import './Tags.less';

type TagsProps = {
  className?: string;
  children: React.ReactNode;
};

type Size = 'large' | 'small';

export type TagProps = {
  children: string;
  title?: string;
  color?: 'cyan' | 'yellow' | 'red' | 'silver' | 'green';
  size?: Size;
  className?: string;
};

export const Tags: React.FunctionComponent<TagsProps> = ({ className, children }) => {
  return <div className={classNames('ui-tags', className)}>{children}</div>;
};

export const Tag: React.FunctionComponent<TagProps> = ({
  children,
  title,
  color = 'cyan',
  size = 'small',
  className,
}) => {
  return (
    <span title={title || children} className={classNames('ui-tag', `ui-tag--${size}`, `bg-${color}`, className)}>
      {children}
    </span>
  );
};
