import { PinPreview } from '@lessonup/pin-renderer';
import { TeacherPin } from '@lessonup/pins-shared';
import { Lesson } from '@lessonup/teaching-core';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowLeft from '../../../../ui/components/svgIcons/ArrowLeft';
import ArrowRight from '../../../../ui/components/svgIcons/ArrowRight';
import { useFontSizeForRef } from '../../../utils/hooks/fontResizeHooks';
import UnstyledButton from '../../UnstyledButton';
import './lessonPreview.less';
import './lessonPreviewV2.less';

const TRANSLATION_NS = 'lessonDetails';

export interface Props {
  lesson: Lesson;
  pins: TeacherPin[];
  preselectedPin?: string;
  onPinSelected?: (pinId: string) => void;
}

export interface State {
  selectedPinIndex: number;
}

export const LessonPreviewV2: React.FC<Props> = ({ pins, preselectedPin, onPinSelected }) => {
  const [selectedPinIndex, setSelectedPinIndex] = useState(getInitialPinIndex());
  const { t } = useTranslation(TRANSLATION_NS);

  function getInitialPinIndex(): number {
    if (!preselectedPin) {
      return 0;
    }

    const pinIndex = pins.findIndex((pin) => pin.id === preselectedPin);

    if (pinIndex === -1) {
      console.warn('lessonPreview got a preselectedPin prop that does not exist!');
      return 0;
    }

    return pinIndex;
  }

  function moveActiveSlide(change: number) {
    const newIndex = selectedPinIndex + change;

    if (newIndex < 0 || newIndex > pins.length - 1) {
      return;
    }

    setActiveSlide(newIndex);
  }

  function setActiveSlide(slideIndex: number) {
    setSelectedPinIndex(slideIndex);

    if (onPinSelected) {
      onPinSelected(pins[slideIndex].id);
    }
  }

  function handleRightClick(e: React.MouseEvent) {
    moveActiveSlide(1);
  }

  function handleLeftClick(e: React.MouseEvent) {
    moveActiveSlide(-1);
  }

  function handleKeyInput(event: React.KeyboardEvent) {
    // Left arrow
    if (event.which === 37) {
      moveActiveSlide(-1);
    }

    // Right arrow
    if (event.which === 39) {
      moveActiveSlide(1);
    }
  }

  const selectedPin = pins[selectedPinIndex];

  if (!selectedPin) {
    throw new Error('No selectedpin!');
  }

  return (
    <div className="relative" onKeyDown={handleKeyInput}>
      <SelectedPinViewV2 pin={selectedPin}>
        <div className="lesson-preview__player-nav">
          <div
            className={classNames('player-nav-button', 'player-nav-button-left', {
              clickable: selectedPinIndex > 0,
            })}
            onClick={handleLeftClick}
          >
            <UnstyledButton className="lesson-preview__player-navbutton">
              <ArrowLeft className="arrow-left" width={10} height={12} />
            </UnstyledButton>
            {selectedPinIndex > 0 && <span className="navigation-text-button">{t('previous')}</span>}
          </div>
          <div className="lesson-preview__player-nav-count">
            <span>
              {selectedPinIndex + 1} / {pins.length}
            </span>
          </div>
          <div
            className={classNames('player-nav-button', 'player-nav-button-right', {
              clickable: selectedPinIndex < pins.length - 1,
            })}
            onClick={handleRightClick}
          >
            {selectedPinIndex < pins.length - 1 && <span className="navigation-text-button">{t('next')}</span>}
            <UnstyledButton className="lesson-preview__player-navbutton">
              <ArrowRight className="arrow-right" width={10} height={12} />
            </UnstyledButton>
          </div>
        </div>
      </SelectedPinViewV2>
      <PinSlideInfoV2 pin={selectedPin} pinIndex={selectedPinIndex} />
    </div>
  );
};

const SelectedPinViewV2: React.FunctionComponent<{ pin: TeacherPin; children: React.ReactNode }> = ({
  pin,
  children,
}) => {
  const { setRef } = useFontSizeForRef();
  // we only scale on small screens

  return (
    <div className="lesson-preview__player" ref={setRef}>
      <PinPreview pin={pin} className="lesson-preview__pin-preview" />
      {children}
    </div>
  );
};

interface PinSlideInfoProps {
  pin: TeacherPin;
  pinIndex: number;
}

function PinSlideInfoV2({ pin, pinIndex }: PinSlideInfoProps) {
  const { t } = useTranslation([TRANSLATION_NS, 'slides']);
  const { type } = pin;

  const translatedString = t(`slides:${type}`);

  return (
    <div className="lesson-preview__slide-info">
      <span>
        Slide {pinIndex + 1}: <strong>{capitalize(translatedString)}</strong>
      </span>
    </div>
  );
}
