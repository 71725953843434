import { isArray } from 'lodash';

export interface LoginToken {
  userId: string;
  token: string;
  redirectUrl: string;
}

export type LtiLocale = 'en' | 'nl';

export interface BaseLtiToken {
  platformId: string;
  deploymentId: string;
  clientId: string;
  platformContext: {
    roles: LtiTeacherRole[];
    custom: {
      email: string;
      family_name: string;
      given_name: string;
      course_id: string;
    };
    context: {
      title: string;
    };
    launchPresentation: {
      locale: LtiLocale;
    };
  };
}

export interface ImpersonatedStudentUserLtiToken {
  platformId: string;
  platformContext: {
    roles: LtiImpersonatedStudentRole[];
    custom: {
      family_name: string;
      given_name: string;
      course_id: string;
      assignmentId?: string;
    };
    context: {
      title: string;
    };
    launchPresentation: {
      locale: LtiLocale;
    };
  };
}

export type DeeplinkLtiToken = BaseLtiToken & {
  platformContext: {
    deepLinkingSettings: {
      deep_link_return_url: string;
    };
  };
};

export type LessonLaunchRequestLtiToken = BaseLtiToken & {
  platformContext: {
    custom: {
      type: string;
      lessonId: string;
      assignmentType: string;
      assignmentId: string;
      groupId: string;
    };
  };
};

export const TEACHER_ROLES = [
  'http://purl.imsglobal.org/vocab/lis/v2/system/person#Administrator',
  'http://purl.imsglobal.org/vocab/lis/v2/system/person#AccountAdmin',
  'http://purl.imsglobal.org/vocab/lis/v2/system/person#SysAdmin',
  'http://purl.imsglobal.org/vocab/lis/v2/system/person#SysSupport',
  'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Administrator',
  'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Faculty',
  'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Staff',
  'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Instructor',
] as const;

export const IMPERSONATED_STUDENT_ROLES = [
  'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Student',
  'http://purl.imsglobal.org/vocab/lti/system/person#TestUser',
];

export type LtiTeacherRole = (typeof TEACHER_ROLES)[number];

export type LtiImpersonatedStudentRole = (typeof IMPERSONATED_STUDENT_ROLES)[number];

export function ltiUserIsTeacher(roles: LtiTeacherRole[]): boolean {
  return roles.some((role) => TEACHER_ROLES.includes(role));
}
export function ltiUserIsImpersonatedStudent(roles: LtiImpersonatedStudentRole[]): boolean {
  return IMPERSONATED_STUDENT_ROLES.every((impersonatedStudentRole) => roles.includes(impersonatedStudentRole));
}

export function loginTokenKey(uuid: string): string {
  return `token.${uuid}`;
}

export function issuedByItsLearning(token: any): boolean {
  return token.iss === 'https://identityserver.itslearning.com';
}

function createLtiIdentifierForCanvas(ltiToken: BaseLtiToken): string {
  return `${ltiToken.platformId}:${ltiToken.platformContext.custom.course_id}`;
}

function createLtiIdentifierForItsLearning(ltiToken: BaseLtiToken): string {
  return `${ltiToken.deploymentId}:${ltiToken.platformContext.custom.course_id}`;
}

export function getLtiIdentifier(ltiToken: BaseLtiToken): string {
  const isItsLearning = issuedByItsLearning(ltiToken);
  return isItsLearning ? createLtiIdentifierForItsLearning(ltiToken) : createLtiIdentifierForCanvas(ltiToken);
}

export function normalizeItsLearningLaunchRequestToken(token: any): LessonLaunchRequestLtiToken {
  return {
    platformId: token.platformId,
    deploymentId: token.deploymentId,
    clientId: token.clientId,
    platformContext: {
      context: token.platformContext.context,
      custom: {
        course_id: token.platformContext.context.id,
        email: token.userInfo.email,
        family_name: token.userInfo.family_name,
        given_name: token.userInfo.given_name,
        assignmentId: token.platformContext.custom.assignmentid,
        type: token.platformContext.custom.type,
        lessonId: token.platformContext.custom.lessonid,
        assignmentType: token.platformContext.custom.assignmenttype,
        groupId: token.platformContext.custom.groupid,
      },
      launchPresentation: token.platformContext.launchPresentation,
      roles: token.platformContext.roles,
    },
  };
}

export function normalizeItsLearningDeeplinkToken(token: any): DeeplinkLtiToken {
  return {
    platformId: token.platformId,
    deploymentId: token.deploymentId,
    clientId: token.clientId,
    platformContext: {
      context: token.platformContext.context,
      deepLinkingSettings: token.platformContext.deepLinkingSettings,
      custom: {
        course_id: token.platformContext.context.id,
        email: token.userInfo.email,
        family_name: token.userInfo.family_name,
        given_name: token.userInfo.given_name,
      },
      launchPresentation: token.platformContext.launchPresentation,
      roles: token.platformContext.roles,
    },
  };
}

export function isBaseLtiToken(token: any): token is BaseLtiToken {
  if (
    !token.platformContext?.context?.title ||
    !token.platformContext?.custom?.course_id ||
    !token.platformContext?.custom.email ||
    token.platformContext?.custom.family_name === undefined ||
    token.platformContext?.custom.given_name === undefined ||
    !token.platformContext?.launchPresentation.locale ||
    !token.platformContext?.roles ||
    !isArray(token.platformContext.roles) ||
    !token.platformId ||
    !token.clientId
  ) {
    return false;
  }

  return true;
}

export function isImpersonatedStudentUserLtiToken(token: any): token is ImpersonatedStudentUserLtiToken {
  if (
    !token.platformContext?.context?.title ||
    !token.platformContext?.custom?.course_id ||
    token.platformContext?.custom.family_name === undefined ||
    token.platformContext?.custom.given_name === undefined ||
    !token.platformContext?.launchPresentation.locale ||
    !token.platformContext?.roles ||
    !isArray(token.platformContext.roles) ||
    !ltiUserIsImpersonatedStudent(token.platformContext.roles) ||
    !token.platformId
  ) {
    return false;
  }

  return true;
}

export function isDeeplinkLtiToken(lti: any): lti is DeeplinkLtiToken {
  return !!lti.platformContext?.deepLinkingSettings?.deep_link_return_url && isBaseLtiToken(lti);
}

export function isMissingValidationCookieError(error: any): boolean {
  return error?.details?.message === 'MISSING_VALIDATION_COOKIE';
}

/**
 * Asserts that the LTI launch request token contains all the custom fields for launching a lesson
 * @param lti The LTI launch request token
 * @returns true if the token is valid for a lesson launch request, false otherwise
 */
export function isLessonLaunchRequestLtiToken(lti: any): lti is LessonLaunchRequestLtiToken {
  return (
    !!lti.platformContext?.custom?.type &&
    !!lti.platformContext?.custom?.lessonId &&
    !!lti.platformContext?.custom?.assignmentType &&
    !!lti.platformContext?.custom?.assignmentId &&
    isBaseLtiToken(lti)
  );
}
