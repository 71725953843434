import { LessonContent, LessonPin } from '@lessonup/teaching-core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FacetMetaData } from '../../../../../shared-core/domain/search/content/FacetMetaData';
import { channelTeacherUrl } from '../../../../../shared-core/services/app/teacherRoutes';
import Checkbox from '../../../../../shared-core/ui/components/Checkbox';
import MetaDataTagList from '../../../../../shared-core/ui/components/MetaDataTagList';
import PinView from '../../../../../shared-core/ui/components/pin/PinView';
import { useAppServices } from '../../../../components/appServices/AppServicesContext';
import VerticalPinList from '../../../../components/lesson/VerticalPinList/VerticalPinList';
import { fetchedLessonAction } from '../../../../redux/actions/actions';
import { lessonDetails } from '../../../../redux/selectors';
import ChannelInfo from '../../../lesson/LessonOverview/ChannelInfo/ChannelInfo';
import { PIN_SEARCH_TRANSLATION_NS } from '../PinSearchPage';
import './PinSearchLessonPage.less';

interface Props {
  pinId: string | undefined;
  lesson: string | undefined;
  closePinInfo: () => void;
  togglePinSelectionState: (pinId: string) => void;
  selectedPins: string[];
}

export const PinSearchLessonPageView: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation(PIN_SEARCH_TRANSLATION_NS);
  const [activePinId, setActivePinId] = useState<string | undefined>(props.pinId);
  const details = useSelector(lessonDetails());
  const services = useAppServices();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const lesson = await services.lesson.lessonById(props.lesson);
      lesson && dispatch(fetchedLessonAction(lesson));
    };

    fetchData();
  }, [dispatch, props.lesson, services.lesson]);

  if (!details || details.version === 'v2') {
    return <div>...</div>;
  }

  const { lesson, channel, pins } = details;

  function activePin(pins: LessonPin<LessonContent>[]) {
    const selectedPin = pins.find((p) => p._id === activePinId);
    return selectedPin || pins[0];
  }

  return (
    <div className="lesson-page pin-search-lessonpage">
      <div className="lesson-preview">
        <div className="lesson-details">
          <BackButton closePinInfo={props.closePinInfo} />
          <div className="pin-search-preview-container">
            <div className="lesson-preview__player pin-font-size-600">
              <PinView pin={activePin(pins)} />
            </div>
          </div>
          <div className="pin-selection-large">
            <Checkbox
              id={'pin-selection-large'}
              checked={props.selectedPins.includes(activePin(pins)._id)}
              onChange={() => props.togglePinSelectionState(activePin(pins)._id)}
              theme="secondary"
              height="large"
              label={t('selectElement')}
            />
          </div>
          <p>{t('lessonNameIntro')}</p>
          <h3 className="lesson-title">{lesson.name}</h3>
          <ChannelInfo
            channel={channel}
            showFollowButton={false}
            channelUrl={channelTeacherUrl}
            lessonId={lesson._id}
          />
          <MetaDataTagList metaData={FacetMetaData.fromLesson({ lesson })} size={'large'} />
        </div>
        <VerticalPinList
          pins={pins}
          selectedPins={props.selectedPins}
          togglePinSelectionState={props.togglePinSelectionState}
          activePin={activePin(pins)._id}
          setActivePin={setActivePinId}
          label={t('verticalPinListDescription')}
        />
      </div>
    </div>
  );
};

interface BackButtonProps {
  closePinInfo: () => void;
}

const BackButton: React.FunctionComponent<BackButtonProps> = ({ closePinInfo }) => {
  const { t } = useTranslation(PIN_SEARCH_TRANSLATION_NS);
  return (
    <div className="lesson-page__back" onClick={closePinInfo}>
      <span className="lesson-page__back-arrow">&lsaquo;</span>
      {t('returnToPinList')}
    </div>
  );
};
