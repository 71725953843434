import { History } from 'history';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { SearchParams } from '../../../shared-core/domain';
import { lessonRoute, lessonSearchRoute } from '../../../shared-core/services/app/searchRoutes';
import { Text } from '../../../shared-core/ui/components/translations/Text/Text';
import { lessonSearchQuery, lessonStore } from '../../redux/selectors';
import { isLessonSearchRoute } from '../../utils/routeUtils/routeUtils';
import LessonPage from './LessonPage';
import LessonPageV2 from './lessonPageV2/LessonPageV2';

type Props = RouteComponentProps & {
  lessonId: string;
  preselectedPin?: string;
};

const LessonRootPage: React.FC<Props> = ({ history, lessonId, preselectedPin }) => {
  const searchQuery = useSelector(lessonSearchQuery());
  const handlePinSelected = (pinId: string) => {
    history.replace({
      pathname: lessonRoute(lessonId, pinId),
    });
  };

  const { queryString, urlDict } = SearchParams.searchParamsToUrlComponents(searchQuery);
  const backUrl = lessonSearchRoute({
    ...urlDict,
    qs: queryString,
  });

  const { lessonDetails } = useSelector(lessonStore());

  if (lessonDetails && lessonDetails.version === 'v2') {
    return (
      <LessonPageV2
        lessonId={lessonId}
        preselectedPin={preselectedPin}
        handlePinSelected={handlePinSelected}
        backUrl={backUrl}
        backNavigation={() => <BackButton url={backUrl} history={history} />}
      ></LessonPageV2>
    );
  }

  return (
    <LessonPage
      lessonId={lessonId}
      preselectedPin={preselectedPin}
      handlePinSelected={handlePinSelected}
      backUrl={backUrl}
      backNavigation={() => <BackButton url={backUrl} history={history} />}
    />
  );
};

export default LessonRootPage;

const BackButton = ({ url, history }: { url: string; history: History }) => {
  const location = useLastLocation();
  // if last location would be search, use browser back as the experience is better
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (location && isLessonSearchRoute(location.pathname)) {
      event.preventDefault();
      history.goBack();
    }
  };

  return (
    <div className="lesson-page__back">
      <span className="lesson-page__back-arrow">&lsaquo;</span>
      <Link to={url} className="link-prominent" onClick={onClick}>
        <Text translateKey="returnToSearch" options={{ ns: 'search' }} />
      </Link>
    </div>
  );
};
