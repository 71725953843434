import {
  addMinutes,
  EnvContext,
  isAfter,
  lowercaseAndTrim,
  RegistrationPurposeType,
  WithOptionalId,
} from '@lessonup/utils';
import _, { cloneDeep, compact, flatten, intersection, uniq } from 'lodash';
import { HashedLoginToken } from '../authorization/Auth';
import { Country } from '../country/Country';
import { UnitedStates } from '../country/UnitedStates';
import { Language, LanguageSingleton } from '../language';
import { Merge } from '../merge';
import { Organization, OrganizationId } from '../organizations';
import { Licenses } from './Licenses';

export const ANONYMIZATION_MASK = 'deleted user' as const;

export type CreateAccountMethod =
  | 'password'
  | 'google'
  | 'office365'
  | 'surfconext'
  | 'som'
  | 'organization-admin-bulk'
  | 'organization-admin-single'
  | 'lessonup-admin'
  | 'classroom-invite'
  | LtiPlatformName;

type ID = string;
// TODO: fill in the rest
export interface MongoUser {
  _id: ID;
  profile?: MongoUserProfile;
  account?: MongoUserAccount;
  mergedAccount?: MongoUserMergedAccount;
  createdAt?: Date;
  emails?: MongoUserEmail[];
  data?: MongoUserData;
  services?: MongoUserServices;
  roles?: ('admin' | 'labs')[];
  status?: {
    lastLogin?: {
      date: Date;
    };
  };
  createAccountMethod?: CreateAccountMethod;
}

export interface MongoUserInOrganization extends MongoUser {
  activityStatus: number;
  organisationRole: MongoUserAccountOrganizationRole;
}

export interface MongoUserEmail {
  address: string;
  verified: boolean;
}

export type MongoUserServiceName = keyof MongoUserServices;

export interface MongoUserServices {
  google?: GoogleSSOService;
  password?: {
    bcrypt: string;
  };
  resume?: {
    loginTokens: HashedLoginToken[];
  };
  somtoday?: any;
  office365?: Office365Service;
  surfconext?: SurfConext;
  lti?: Lti;
  canvas?: Lti;
  managedStudent?: any;

  chartmogul?: {
    id: string;
    syncDate?: Date;
  };
}

export interface GoogleSSOService {
  id: string;
  accessToken?: string;
  sub?: string;
  emails?: { value: string; verified: boolean }[];
  name?: string;
  photos?: { value: string }[];
  displayName?: string;
  given_name?: string;
  gender?: 'male' | 'female' | undefined;
  family_name?: string;
  picture?: string;
  email: string;
  email_verified: boolean;
  locale: string;
}

export interface MicrosoftIdentity {
  id: string;
  userPrincipalName: string;
  businessPhones: string[];
  displayName: null | string;
  jobTitle: null | string;
  mail: null | string;
  mobilePhone: null | string;
  officeLocation: null | string;
  preferredLanguage: null | string;
  surname: null | string;
  givenName: null | string;
  department: null | string;
  schools: null | string;
  companyName: null | string;
}

export interface Office365Service
  extends Partial<
    Pick<
      MicrosoftIdentity,
      | 'jobTitle'
      | 'mobilePhone'
      | 'businessPhones'
      | 'officeLocation'
      | 'preferredLanguage'
      | 'department'
      | 'schools'
      | 'companyName'
    >
  > {
  id: string | null;
  accessToken: string | null;
  displayName: string | null;
  givenName: string | null;
  surname: string | null;
  username: string | null;
  userPrincipalName: string | null;
  /** CAREFUL, this field can't be trusted! https://lessonup.atlassian.net/browse/DEFECT-1497 */
  mail: string | null;
}

export const allMongoUserServiceEmailFields = [
  'services.google.email',
  'services.office365.mail',
  'services.office365.email',
  'services.office365.userPrincipalName',
];

export interface SurfConext {
  acr: string | null;
  email: string | null;
  email_verified: boolean | null;
  family_name: string | null;
  given_name: string | null;
  sub: string | null;
  updated_at: number | null;
}

export interface ValidatedSurfConextUser extends SurfConext {
  email: string;
  family_name: string;
  given_name: string;
}

export interface Lti {
  family_name: string;
  given_name: string;
  email: string;
  platformId: string;
  deploymentId?: string;
}

export type LtiPlatformName = 'itslearning' | 'canvas';

export type MongoUserActiveDocument =
  | {
      type: 'lesson';
      lessonId: string;
      pinId?: string;
    }
  | {
      type: 'lessonV2';
      lessonId: string;
      pinId?: string;
    }
  | {
      type: 'plan';
      planId: string;
    };

export interface MongoUserData {
  language?: Language.Key;
  sortStudentsBy?: 'display' | 'family';
  USState?: UnitedStates.StateCode;
  /** @deprecated */
  'skipConfirm-pressedTeach'?: 0 | 1;
  progress?: Partial<MongoUserProgress>;
  following?: Record<string, true>;
  region?: Country.CountryCode;
  country?: Country.CountryCode;
  receiveEmails?: boolean;
  customColors?: string[];
  pins?: number;
  ask_profile_at?: Date;
  ask_profile_attempt?: number;
  NLthemes?: string[];
  subjects?: string[];
  schoolTypes?: string[];
  /** @deprecated */
  purpose?: RegistrationPurposeType;
  reasonForSignup?: string;
  LessonLib_MyActiveObject?: MongoUserActiveDocument;
  /**
   * @deprecated Use subjects
   */
  IntSubjects?: string[];

  /**
   * @deprecated Use subjects
   */
  NLSubjects?: string[];

  /**
   * @deprecated Use subjects
   */
  BESubjects?: string[];

  /**
   * @deprecated Use Subjects
   */
  USSubjects?: string[];
}

const progressDateKeys = [
  'add-dragquestion',
  'create-pin',
  'finish-presenting',
  'add-quiz',
  'add-slide',
  'share-lesson',
  'create-new-lesson',
  'add-openquestion',
  'add-wordweb',
  'add-imagequestion',
];

const progressBooleanKeys = [
  'deel_les_met_leerlingen',
  'upload_afbeelding',
  'volgende_pin_presenteren',
  'dia_kleur_kiezen',
  'dia_layout_veranderen',
  'pijltjes_links_rechts_gebruiken',
  'voeg_google_map_toe',
  'url_toevoegen',
  'voeg_streetview_toe',
  'sluit_presentatie_met_escape',
  'dia_tekstcentrering_veranderen',
  'upload_powerpoint',
  'start-test',
  'save-lesson',
];
type ProgressDateKey =
  | 'add-dragquestion'
  | 'create-pin'
  | 'finish-presenting'
  | 'add-quiz'
  | 'add-slide'
  | 'share-lesson'
  | 'create-new-lesson'
  | 'add-openquestion'
  | 'add-wordweb'
  | 'add-imagequestion';

type ProgressBooleanKey =
  | 'deel_les_met_leerlingen'
  | 'upload_afbeelding'
  | 'volgende_pin_presenteren'
  | 'dia_kleur_kiezen'
  | 'dia_layout_veranderen'
  | 'pijltjes_links_rechts_gebruiken'
  | 'voeg_google_map_toe'
  | 'url_toevoegen'
  | 'voeg_streetview_toe'
  | 'sluit_presentatie_met_escape'
  | 'dia_tekstcentrering_veranderen'
  | 'upload_powerpoint'
  | 'start-test'
  | 'save-lesson';

export type ProgressKey = ProgressDateKey; // We only expose the date key as this is the format we want to use moving forward.

export interface MongoUserProgress extends Record<ProgressDateKey, Date>, Record<ProgressBooleanKey, 0 | 1> {
  [key: string]: Date | 0 | 1;
}

/** make sure to update the properties in types/meteor/index.d.ts (UserProfile) if you change the signature of a property */
export interface MongoUserProfile {
  name?: string;
  email?: string;
  family_name?: string;
  gender?: 'male' | 'female';
  given_name?: string;
  picture?: string;
  school?: MongoUserSchool;
  hasSeenChildPickerPage?: boolean;
  subjects?: string[];
  schoolTypes?: string[];
  pins?: number;
}

export const mongoUserAccountOrganizationRoles = ['member', 'basic', 'pending'] as const;
export type MongoUserAccountOrganizationRole = (typeof mongoUserAccountOrganizationRoles)[number];

export interface MongoUserAccountOrganization {
  id: string;
  role: MongoUserAccountOrganizationRole;
  isAdmin?: boolean;
  requestedUpgradeToMember?: true;
  locations?: string[];
  joinedViaRule?: string;
  joinDate?: Date;
  name?: string;
}

export interface MongoUserSharedFolders {
  owner: string[];
  member: string[];
}

export interface MongoUserAccountFollowing {
  slug: string;
  date: Date;
}

export interface MongoUserAccountLegacyPayments {
  customerId?: string;
  subscriptionId?: string;
  subscription: {
    id: string;
    mode: 'test' | 'live';
    createdDatetime: string | Date;
    amount: string;
    interval: '1 month' | '12 months';
    description: string;
    cancelledDatetime: Date | string | null;
    status: any;
    resource: any;
    method?: any;
    times?: any;
    [key: string]: any;
  };
}

export type MongoUserAccountLoginType =
  | 'password'
  | 'google'
  | 'office365'
  | 'surfconext'
  | 'canvas'
  | 'lti'
  | 'unknown';
export interface MongoUserAccount {
  /**
   * @deprecated
   */
  email?: string;
  prefPlayerId?: string;
  isStudent?: boolean;
  lockedStudent?: boolean;
  explorerOwner?: string[];
  explorerMember?: string[];
  appliedLicense?: MongoUserAppliedLicense;
  organizations?: MongoUserAccountOrganization[];
  channels?: string[];
  acceptedTerms?: Date | undefined;
  payments?: MongoUserAccountLegacyPayments;
  subscription?: any;
  stripe?: string; // stripe customer id
  loginTypes?: MongoUserAccountLoginType[];
  currentLicense?: MongoUserCurrentLicense;
  originApp?: EnvContext.App;
  following?: MongoUserAccountFollowing[];
  featureFlags?: string[];
  ambassadorSince?: Date;
  administrativeName?: MongoUserAdministrativeName;
  isManagedByTeacher?: boolean;
}

export interface MongoUserMergedAccount extends MongoUserAccount {
  mergedTo: string;
  mergeDate: Date;
}

export interface MongoUserAdministrativeName {
  given: string;
  last: string;
  infix?: string;
}

interface MongoUserSchoolFromSuggestion {
  globalSchoolId?: string;
  entryDate: Date;
}

interface MongoUserSchoolFromManualEntry {
  name?: string;
  city?: string;
  entryDate?: Date;
}
export type MongoUserSchool = MongoUserSchoolFromSuggestion | MongoUserSchoolFromManualEntry;

export interface MongoUserAppliedLicense {
  status: Licenses.Status;
  checkDate: Date;
  expires?: Licenses.ExpiryDate;
  expiredForSchool?: boolean;
  expiredFromTrial?: boolean;
  isSubscription?: boolean;
  organization?: OrganizationId[];
}

type MongoUserCurrentLicenseWithoutHistory = Omit<MongoUserCurrentLicense, 'history'>;
export interface MongoUserCurrentLicense {
  history?: (MongoUserCurrentLicenseHistory | MongoUserCurrentLicenseWithoutHistory)[];
  status: Licenses.Status;
  since?: Date;
  expires?: Date;
}

export interface MongoUserCurrentLicenseHistory {
  voucher?: string;
  product?: string;
  organization?: string;
  claimDate?: Date;
  label?: string;
}

export interface WithDisplayName extends MongoUser {
  displayName: string;
}

export namespace MongoUser {
  export const maxLength = 100;

  export interface NewUserParams {
    profile: MongoUserProfile;
    emails: MongoUserEmail[];
    account?: MongoUserAccount;
    services?: MongoUserServices;
    data?: MongoUserData;
    createAccountMethod?: CreateAccountMethod;
  }

  export function newUser({
    emails,
    profile,
    account = {},
    services = {},
    data = {},
    createAccountMethod,
  }: NewUserParams): WithOptionalId<MongoUser> {
    const newAccount: MongoUserAccount = {
      ...account,
      email: lowercaseAndTrim(account.email || '') || undefined,
    };
    if (!newAccount.currentLicense) {
      newAccount.currentLicense = Licenses.defaultLicense();
    }
    if (!newAccount.appliedLicense) {
      newAccount.appliedLicense = {
        status: newAccount.currentLicense.status,
        expires: newAccount.currentLicense.expires,
        // If Workers is online we want it checked immediately, so this should be in the past.
        checkDate: new Date('1999'),
      };
    }

    const newServices = cloneDeep(services);

    if (newServices.office365?.userPrincipalName) {
      newServices.office365.userPrincipalName = lowercaseAndTrim(newServices.office365.userPrincipalName);
    }
    if (newServices.office365?.mail) newServices.office365.mail = lowercaseAndTrim(newServices.office365.mail);

    if (newServices.google?.email) newServices.google.email = lowercaseAndTrim(newServices.google.email);

    if (newServices.lti?.email) newServices.lti.email = lowercaseAndTrim(newServices.lti.email);

    return {
      profile,
      account: newAccount,
      data: {
        ...data,
        language: data.language || LanguageSingleton.get(),
      },
      services: newServices,
      emails: emails.map((email) => ({ ...email, address: lowercaseAndTrim(email.address) })),
      createdAt: new Date(),
      ...(createAccountMethod != null && { createAccountMethod }),
    };
  }

  export function displayName(user: MongoUser | undefined): string {
    const name = (user && nameFromMongoUser(user)) || '';
    return name.substring(0, 100);
  }

  export function familyName(user: MongoUser | undefined): string {
    const familyName = (user && nameFromMongoUser(user, true)) || '';
    return familyName.substring(0, 100);
  }

  export function hasAdministrativeName(user: MongoUser | undefined): boolean {
    return !!user?.account?.administrativeName?.given;
  }

  export function appliedLicense(user: MongoUser | undefined): MongoUserAppliedLicense | undefined {
    return user && user.account?.appliedLicense;
  }

  export function lockedStudentShouldBeDeleted(user: MongoUser | undefined): boolean {
    const userCreatedAt = createdAt(user);
    if (!user?.account?.lockedStudent || !userCreatedAt) return false;
    const deleteDeadline = addMinutes(new Date(), -15);
    return isAfter(userCreatedAt, deleteDeadline);
  }

  export function service<S extends MongoUserServiceName>(
    user: MongoUser | undefined,
    service: S
  ): MongoUserServices[S] | undefined {
    if (user && user.services && user.services[service]) {
      return user && user.services && (user.services[service] as MongoUserServices[S]);
    }
  }

  export function hasLoginType(user: MongoUser | undefined, loginType: MongoUserAccountLoginType): boolean {
    return (user?.account?.loginTypes || []).includes(loginType);
  }

  export function isManagedByTeacher(user: MongoUser | undefined): boolean {
    return !!user?.account?.isManagedByTeacher;
  }

  export function canResetPassword(user: MongoUser | undefined): boolean {
    return hasLoginType(user, 'password') && !isManagedByTeacher(user);
  }

  export function validateLoginPassword(password: string): boolean {
    return password.length > 5 && password.length <= 100;
  }

  export function validateEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  export function emailFromMongoUser(user: MongoUser): string | undefined {
    let email;
    if (user.emails && user.emails[0]) email = user.emails[0].address;
    if (!email) return undefined;
    return email.trim().toLowerCase();
  }

  export function allEmailsForUser(user: WithOptionalId<MongoUser>): string[] {
    const userProfileEmail = user.profile?.email;
    const userEmails = user.emails?.map((email) => email.address) || [];
    return _.uniq(userEmails?.concat(userProfileEmail || []));
  }

  export function nameFromMongoUser(user: MongoUser | undefined, familyName?: boolean): string {
    if (!user || !user.profile) {
      return '';
    }

    if (familyName) {
      return user.profile.family_name ? user.profile.family_name : '';
    }

    if (user.account && user.account.isStudent !== true) {
      if (user.profile.name) {
        return user.profile.name;
      }
    }

    if (!_.isNil(user.profile.given_name) && !_.isEmpty(user.profile.given_name)) {
      if (user.profile.given_name === ANONYMIZATION_MASK) {
        return user.profile.given_name;
      }

      return user.profile.family_name
        ? user.profile.given_name + ' ' + user.profile.family_name
        : user.profile.given_name;
    }

    if (!_.isNil(user.profile.family_name) && !_.isEmpty(user.profile.family_name)) {
      return user.profile.family_name;
    }

    if (user.profile.name) {
      return user.profile.name;
    }

    return '';
  }

  export function sortMongoUsersByName(sortBy: 'display' | 'family', users: MongoUser[]) {
    return _.sortBy(users, (user) => {
      return nameFromMongoUser(user, sortBy === 'family').toLowerCase();
    });
  }

  export function organizationList(user: Partial<MongoUser> | undefined): MongoUserAccountOrganization[] {
    return user?.account?.organizations || [];
  }

  export function featureFlagList(user: Partial<MongoUser> | undefined): string[] {
    return user?.account?.featureFlags || [];
  }

  export function belongsToOrganization(user: MongoUser | undefined, organizationId: string): boolean {
    return organizationList(user).some((userOrganization) => userOrganization.id === organizationId);
  }

  export function channelList(user: MongoUser): string[] | undefined {
    const channels = _.get(user, 'account.channels');
    return channels && channels.length ? channels : undefined;
  }

  export function adminOrganizationList(user: Partial<MongoUser> | undefined): MongoUserAccountOrganization[] {
    return organizationList(user).filter((o) => o.isAdmin);
  }

  export function memberOfOrganizationList(user: MongoUser | undefined): MongoUserAccountOrganization[] {
    return organizationList(user).filter((o) => o.role === 'member');
  }

  export function organizationById(user: MongoUser | undefined, id: string): MongoUserAccountOrganization | undefined {
    return organizationList(user).find((o) => o.id === id);
  }

  export function roleForOrganization(
    user: MongoUser | undefined,
    organizationId: string
  ): MongoUserAccountOrganizationRole | undefined {
    return organizationList(user).find((o) => o.id === organizationId)?.role;
  }

  export function organizationsForRole(
    user: MongoUser | undefined,
    role: MongoUserAccountOrganizationRole
  ): MongoUserAccountOrganization[] {
    return organizationList(user).filter((o) => o.role === role);
  }

  export function roleToGiveForAvailableOrganizationLicense(
    initialRole: MongoUserAccountOrganizationRole,
    licenseAvailable: boolean
  ): MongoUserAccountOrganizationRole {
    return initialRole === 'member' && !licenseAvailable ? 'basic' : initialRole;
  }

  export function userHasFeatureFlag(user: MongoUser | undefined, featureFlag: string): boolean {
    const userFeatureFlags = featureFlagList(user);
    return userFeatureFlags.includes(featureFlag);
  }

  export function sharedFolders(user: MongoUser | undefined): MongoUserSharedFolders {
    return {
      owner: (user && user.account && user.account.explorerOwner) || [],
      member: (user && user.account && user.account.explorerMember) || [],
    };
  }

  export function sharedFolderIds(user: MongoUser | undefined): string[] {
    const folders = sharedFolders(user);
    return [...folders.member, ...folders.owner];
  }

  export function hasSharedExplorer(user: MongoUser | undefined): boolean {
    const sharedExplorer = sharedFolders(user);
    return Boolean(user && (sharedExplorer.owner.length || sharedExplorer.member.length));
  }

  export function sortedByName(users: MongoUser[]): WithDisplayName[] {
    const withName: WithDisplayName[] = users.map((user) => {
      return {
        displayName: displayName(user),
        ...user,
      };
    });

    return _.sortBy(withName, (u) => u.displayName.toLowerCase());
  }

  export function sortStudentsByFamilyName<U extends MongoUser | WithDisplayName = MongoUser | WithDisplayName>(
    users: U[]
  ): U[] {
    return _.sortBy(users, (u) => u?.profile?.family_name?.toLowerCase());
  }

  export function isAdmin(user: MongoUser | undefined) {
    return Boolean(user && user.roles && user.roles.includes('admin'));
  }

  export function isBasicMemberOfOrganization(user: MongoUser | undefined, organizationId: string): boolean {
    return !!organizationList(user).find((org) => org.id === organizationId && org.role === 'basic');
  }

  export function isAdminOfOrganization(user: MongoUser | undefined, organizationId: string): boolean {
    return adminOrganizationList(user).some((org) => org.id == organizationId);
  }

  export function isAdminOfAnyOrganization(user: MongoUser | undefined): boolean {
    return adminOrganizationList(user).length > 0;
  }

  export function isMemberOfOrganization(user: MongoUser | undefined, organizationId: string): boolean {
    return memberOfOrganizationList(user).some((org) => org.id == organizationId);
  }

  export function isMerged(user: MongoUser | Merge.Source): user is Merge.Source {
    return 'mergedAccount' in user;
  }

  export function license(user: MongoUser | undefined): Licenses.Status | undefined {
    return appliedLicense(user)?.status;
  }

  export function licenseExpires(user: MongoUser): Licenses.ExpiryDate | undefined {
    return appliedLicense(user)?.expires;
  }

  export function hasGroupsFeature(user: MongoUser): boolean {
    return Licenses.isPro(user);
  }

  export function activeLesson(user: MongoUser | undefined): string | undefined {
    return user?.data?.LessonLib_MyActiveObject?.type === 'lesson'
      ? user.data.LessonLib_MyActiveObject.lessonId
      : undefined;
  }

  export function activeDocument(user: MongoUser | undefined): MongoUserActiveDocument | undefined {
    return user?.data?.LessonLib_MyActiveObject;
  }

  export function hasActiveLegacySubscription(user: MongoUser): boolean | undefined {
    const licenseBought = user.account && user.account.appliedLicense && user.account.appliedLicense.status;
    const licenseBoughtExpiryDate = legacySubscriptionExpireDate(user);
    return licenseBought === 'pro' && licenseBoughtExpiryDate && new Date() < licenseBoughtExpiryDate;
  }

  export function legacySubscriptionExpireDate(user: MongoUser): Date | undefined {
    const licenseBoughtExpiryDate = user.account && user.account.appliedLicense && user.account.appliedLicense.expires;
    return licenseBoughtExpiryDate && new Date(licenseBoughtExpiryDate);
  }

  export function language(user: WithOptionalId<MongoUser> | undefined): Language.Key {
    return (user && user.data && user.data.language) || Language.defaultLanguage;
  }

  export function isStudent(user: MongoUser | undefined): boolean {
    if (!user) return false;
    return !!(user.account && user.account.isStudent);
  }

  export function schoolTypes(user: MongoUser | undefined): string[] | undefined {
    if (!user) return undefined;

    return uniq([...(user.profile?.schoolTypes || []), ...(user.data?.schoolTypes || [])]);
  }

  export function subjects(user: MongoUser | undefined): string[] | undefined {
    if (!user) return undefined;

    return uniq([...(user.profile?.subjects || []), ...(user.data?.subjects || [])]);
  }

  export function purpose(user: MongoUser | undefined): RegistrationPurposeType | undefined {
    if (!user) return undefined;

    return user.data?.purpose;
  }

  export function country(user: MongoUser | undefined): Country.CountryCode | undefined {
    return user && user.data && user.data.country;
  }

  export function USState(user: MongoUser | undefined): UnitedStates.StateCode | undefined {
    return user && user.data && user.data.USState;
  }

  export function followingChannels(user: MongoUser | undefined): string[] {
    if (!user) return [];
    const dict = user?.data?.following || {};
    const oldKeys = Object.entries(dict)
      .filter(([slug, bool]) => bool)
      .map(([slug, bool]) => slug);
    const newKeys = (user.account?.following || []).map((follow) => follow.slug);
    return _.uniq(oldKeys.concat(newKeys));
  }

  export function followingDict(user: MongoUser | undefined): Record<string, boolean> {
    const dict: Record<string, boolean> = {};
    followingChannels(user).forEach((slug) => {
      dict[slug] = true;
    });
    return dict;
  }

  const SSOServiceList: Record<keyof MongoUserServices, boolean> = {
    google: true,
    password: false,
    somtoday: true,
    resume: false,
    office365: true,
    surfconext: true,
    canvas: true,
    lti: true,
    managedStudent: false,
    chartmogul: false,
  };

  export function isSSOService(serviceName: MongoUserServiceName): boolean {
    return SSOServiceList[serviceName];
  }

  export function isSSOEmailAddress(user: MongoUser, email: string): boolean {
    if (!user.services) return false;

    return [getOffice365Mail(user.services?.office365), getGoogleSSOMail(user.services?.google)].some(
      (serviceEmail) => serviceEmail === email
    );
  }

  export function loginTypesContainsSSO(user: MongoUser): boolean {
    return intersection(user.account?.loginTypes, ['google', 'office365', 'surfconext']).length > 0;
  }

  export function isEmailAddressVerified(user: MongoUser, email: string): boolean {
    return !!user.emails?.find((entry) => entry.address === email)?.verified;
  }

  export function idFromMongoUserOrId(params: MongoUserOrId): string {
    return 'user' in params ? params.user._id : params.userId;
  }

  export function licenseHistoryRecordForOrganization(
    organization: Organization,
    action: 'connect' | 'disconnect'
  ): MongoUserCurrentLicenseHistory {
    const product = action === 'connect' ? 'Gekoppeld aan Organization License' : 'Ontkoppeld van Organization License';

    return {
      voucher: '-',
      product,
      organization: organization._id,
      claimDate: new Date(),
      label: `${product} ${organization.name}`,
    };
  }

  export function createdAfter(user: MongoUser | undefined, date: Date): boolean {
    const userCreatedAt = createdAt(user);
    if (!userCreatedAt) return false;
    return isAfter(userCreatedAt, date);
  }

  export function createdAt(user: MongoUser | undefined): Date | undefined {
    if (!user?.createdAt) return;
    return new Date(user.createdAt);
  }

  export function ambassadorSince(user: MongoUser): Date | undefined {
    return user.account?.ambassadorSince;
  }
  export function preferredFontForUser(user: MongoUser | undefined) {
    return user?.data?.['default-font'];
  }

  export function getCurrentLicense(user: MongoUser): MongoUserCurrentLicense | undefined {
    return user.account?.currentLicense;
  }
}

export function getSSOMailFromUser(user: MongoUser, serviceName: MongoUserServiceName): string | undefined {
  return serviceName === 'google' && user.services?.google
    ? getGoogleSSOMail(user.services?.google)
    : serviceName === 'office365' && user.services?.office365
    ? getOffice365Mail(user.services?.office365)
    : '';
}

export function getOffice365Mail(service?: Office365Service): string {
  // don't use the mail, it's potentially unsafe
  return service?.userPrincipalName || '';
}

export function getGoogleSSOMail(service?: GoogleSSOService): string | undefined {
  return service?.email;
}

export function validateSurfConextUser(user: SurfConext): ValidatedSurfConextUser {
  if (!user.email || !user.family_name || !user.given_name) {
    throw new Error("Doesn't have the required information");
  }
  return {
    ...user,
    email: user.email,
    family_name: user.family_name,
    given_name: user.given_name,
  };
}

type ShortlistName = 'subjects' | 'NLSubjects' | 'BESubjects' | 'INTSubjects' | 'NLthemes';

export function dataShortlistForUser(user: MongoUser, fieldNames?: ShortlistName | ShortlistName[]): string[] {
  if (!fieldNames) {
    return [];
  }

  if (Array.isArray(fieldNames)) {
    return compact(flatten(fieldNames.map((name) => user.data?.[name])));
  }

  return user.data?.[fieldNames] || [];
}

interface UserParams {
  user: MongoUser;
}
interface UserIdParams {
  userId: string;
}

export type MongoUserOrId = UserParams | UserIdParams;
