import { hideChatIcon } from '@lessonup/intercom';
import { AssignmentType } from '@lessonup/teaching-core';
import React, { useEffect, useState } from 'react';
import {
  Explorer,
  ExplorerLessonContent,
  ExplorerUserContent,
  isLessonContent,
  Location,
  SharedExplorer,
} from '../../../../shared-core/domain/newExplorers';
import {
  CondensedExplorer,
  SelectionContextProvider,
  useContents,
} from '../../../../shared-core/ui/components/newExplorer';
import CanvasConfirmSelection from '../CanvasConfirmSelection/CanvasConfirmSelection';

export interface CanvasLessonPickerParams {
  rootExplorer: Explorer;
  initialExplorer: Explorer;
  sharedExplorers: SharedExplorer[];
  fetchLessonsAndPlansForLocation: (location: Location) => Promise<ExplorerUserContent[]>;
  setActiveExplorer?: (explorerId: string) => void;
  submitForm: ({ assignmentType, lessonId }: { assignmentType: AssignmentType; lessonId: string }) => Promise<void>;
}

export const CanvasLessonPicker: React.FC<CanvasLessonPickerParams> = ({
  rootExplorer,
  initialExplorer,
  sharedExplorers,
  fetchLessonsAndPlansForLocation,
  setActiveExplorer,
  submitForm,
}) => {
  const [location, setLocation] = useState<Location>({ explorer: initialExplorer._id, folder: initialExplorer._id });
  const [selectedContent, setSelectedContent] = useState<ExplorerLessonContent[]>();

  useEffect(() => {
    hideChatIcon();
  });

  const changeLocation = (location: Location) => {
    setLocation(location);
    setActiveExplorer?.(location.explorer);
  };

  const onContentSelect = (content: ExplorerUserContent[]) => {
    const selectedContentIsNotLesson = !content.filter(isLessonContent).length;
    if (selectedContentIsNotLesson) return;
    setSelectedContent(content.filter(isLessonContent));
  };

  const { contents, explorer: currentExplorer } = useContents(
    rootExplorer,
    initialExplorer,
    location,
    sharedExplorers,
    fetchLessonsAndPlansForLocation
  );

  if (selectedContent?.length) {
    return (
      <div>
        <CanvasConfirmSelection
          content={selectedContent}
          submitForm={submitForm}
          onCancel={() => setSelectedContent([])}
        />
      </div>
    );
  }

  return (
    <SelectionContextProvider>
      <CondensedExplorer
        contents={contents}
        currentExplorer={currentExplorer}
        location={location}
        onLocationChange={changeLocation}
        contentSelection={{ onContentSelect }}
        rootExplorer={rootExplorer}
        enabledTypes={['lesson', 'folder', 'sharedExplorer']}
        disableModernLessons={true}
      ></CondensedExplorer>
    </SelectionContextProvider>
  );
};
