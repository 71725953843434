import { FontLoader, PinPreview } from '@lessonup/pin-renderer';
import { PinType, TeacherPin } from '@lessonup/pins-shared';
import {
  shouldShowTeacherCreditsIndicator,
  TeachingCreditsIndicator,
  TeachingCreditsIndicatorFeature,
  useTeachingCredits,
} from '@lessonup/teacher-modern';
import { Lesson, LessonAttachment, LessonPin } from '@lessonup/teaching-core';
import {
  Banner,
  Button as ModernButton,
  IconDownload,
  IconLesson,
  IconShare,
  Link,
  spacing,
  styled,
} from '@lessonup/ui-components';
import { capitalize } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  ChannelTitle,
  IndexedChannelInfo,
  Language,
  LanguageSingleton,
  LessonDetailsAccessTypes,
  LessonTrigger,
  Licenses,
  UserContext,
} from '../../../../../shared-core/domain';
import { FacetMetaData } from '../../../../../shared-core/domain/search/content/FacetMetaData';
import { helpdeskArticle } from '../../../../../shared-core/services/app/helpdeskRoutes';
import { lessonRoute } from '../../../../../shared-core/services/app/searchRoutes';
import Button from '../../../../../shared-core/ui/components/Button';
import ContextMenu from '../../../../../shared-core/ui/components/ContextMenu/ContextMenu';
import Heading from '../../../../../shared-core/ui/components/Heading';
import { LessonPreviewV2 } from '../../../../../shared-core/ui/components/lesson/lessonPreview/LessonPreviewV2';
import MetaDataTagList from '../../../../../shared-core/ui/components/MetaDataTagList';
import { SUBJECTS_LIMIT } from '../../../../../shared-core/ui/components/MetaDataTagList/MetaDataTagList';
import PinView from '../../../../../shared-core/ui/components/pin/PinView';
import UnstyledButton from '../../../../../shared-core/ui/components/UnstyledButton';
import { FlexBoxDummies } from '../../../../../shared-core/ui/utils/FlexBoxDummies';
import useToggle from '../../../../../shared-core/ui/utils/useToggle';
import listFormat from '../../../../../shared-core/utils/listFormat';
import { PinViewUtils } from '../../../../../shared-core/utils/PinViewUtils';
import { useAppServices } from '../../../../components/appServices/AppServicesContext';
import { followChannelAction } from '../../../../redux/actions/actions';
import { ClientOnlyModernWrapper } from '../../../../utils/bridge/ClientOnlyModernWrapper';
import { htmlDecode } from '../../../../utils/htmlDecode';
import { domainLicenseToLicenseStatus } from '../../helpers/domainLicenseToLicenseStatus';
import ChannelInfo from '../../LessonOverview/ChannelInfo/ChannelInfo';
import { DownloadAttachment } from '../../LessonOverview/components/DownloadAttachment';
import '../../LessonOverview/LessonOverview.less';
import {
  incentiveCounterTeachesLeft,
  incrementTeachCountIfApplicable,
  userHasReachedIncentiveCount,
  userIsInIncentiveCounterTest,
} from '../../utils';
import './LessonOverviewV2.less';

const TRANSLATION_NS = 'lessonDetails';

const InteractivePinTypes: PinType[] = ['DRAG_QUESTION', 'OPEN_QUESTION', 'MULTIPLE_CHOICE', 'WORD_CLOUD'];

export interface AnonymousFollowProps {
  channel: string;
  email: string;
  firstName: string;
  lastName: string;
  organization: string;
  city: string;
  level: string;
}

export type AddPinToActiveLesson = (pinId: string) => Promise<boolean>;

export interface LessonOverviewV2Props {
  lesson: Lesson;
  channel: IndexedChannelInfo | undefined;
  pins: TeacherPin[];
  navigateToLesson: (newTab?: boolean) => void;
  onLessonSave?: (lesson: Lesson, cancelOnLoginDismiss?: boolean) => void;
  onPinSelected?: (pinId: string) => void;
  shareLesson: () => void;
  accessTypes: LessonDetailsAccessTypes;
  preselectedPin?: string;
  isLessonSaved: boolean;
  flagLessonButton?: JSX.Element | undefined;
  channelUrl: (slug: string) => string;
  channelFollowed: boolean;
  userIsLoggedIn: boolean;

  addPin?: AddPinToActiveLesson;
  activeLesson?: UserContext['activeLesson'];
  activeDocument?: UserContext['activeDocument'];
  embedLesson?: boolean;
  currentLicense: Licenses.Status | undefined;
  eligibleToTeachWithoutCredits: boolean;
}

function LessonOverviewV2({
  lesson,
  preselectedPin,
  channel,
  pins,
  isLessonSaved,
  onLessonSave,
  shareLesson,
  accessTypes,
  onPinSelected,
  flagLessonButton,
  channelUrl,
  navigateToLesson,
  channelFollowed,

  userIsLoggedIn,
  addPin,
  activeLesson,
  activeDocument,
  embedLesson,
  currentLicense,
  eligibleToTeachWithoutCredits,
}: LessonOverviewV2Props) {
  const { t } = useTranslation(TRANSLATION_NS);
  const [tagsExpanded, toggleTagsExpanded] = useToggle(false);
  const services = useAppServices();
  const dispatch = useDispatch();
  const { isTeachingAllowed, handleNotAllowedToTeach } = useTeachingCredits();
  const [clientLoaded, setClientLoaded] = useState(false);
  useEffect(() => {
    setClientLoaded(true);
  }, [setClientLoaded]);

  async function handleLessonSave() {
    if (userIsLoggedIn && !(await requestFollowIfRequired('save-lesson'))) {
      return;
    }

    const requireFollow = channel && channel.name && channel.requireFollow;
    if (!onLessonSave) return;
    onLessonSave(lesson, Boolean(requireFollow));
  }

  async function handleTeachClick(newTab?: boolean) {
    if (!eligibleToTeachWithoutCredits && !services.user.currentUser()) {
      const loginResponse = await services.modals.open('loginPrompt', { forceNewTab: !!embedLesson, action: 'teach' });
      if (loginResponse.dismiss) return;
    }
    if (!isTeachingAllowed) {
      handleNotAllowedToTeach();
      return;
    }
    const requestChannelFollow = await requestFollowIfRequired('play-lesson');
    if (!requestChannelFollow) {
      return;
    }
    const showLoginOrRegisterModal = userHasReachedIncentiveCount(!!embedLesson, lesson, userIsLoggedIn);

    if (showLoginOrRegisterModal) {
      const promptType = userIsInIncentiveCounterTest(!!embedLesson, lesson, userIsLoggedIn);

      services.modals.open('registerOrLogin', {
        redirectUrl: lessonRoute(lesson._id, preselectedPin),
        titleOverride: promptType === 'hard' ? t('hardLimitReachedTitle') : t('mediumLimitReachedTitle'),
        descriptionOverride: t('limitReachedDescription'),
      });

      return;
    }

    incrementTeachCountIfApplicable(!!embedLesson, lesson, userIsLoggedIn);

    return navigateToLesson(Boolean(newTab));
  }

  function teachButtonText() {
    const count = incentiveCounterTeachesLeft(!!embedLesson, lesson, userIsLoggedIn);
    if (typeof count === 'undefined') return t('teach');

    return count === 0 ? t('teachLockedForIncentiveCounter') : t('teachLimited', { count });
  }

  async function requestFollowIfRequired(trigger: LessonTrigger['trigger']): Promise<boolean> {
    if (channelFollowed) {
      return true;
    }

    if (channel && channel.name && channel.requireFollow) {
      const products = await services.user.currentUser()?.products;

      if (products) {
        const hasVoucherForChannel = products?.some((product) => product.channelId === channel._id);
        if (hasVoucherForChannel) return true;
      }

      const { success } = await services.channel.requireFollow({
        channelSlug: channel.name,
        channelTitle: channel.title && ChannelTitle.localizeTitle(channel.title),
        trigger: {
          lessonId: lesson._id,
          trigger,
          type: 'lesson',
        },
        userId: services.user.currentUser()?._id,
      });
      if (success) {
        dispatch(followChannelAction(channel.name));
      }

      return success;
    }

    return true;
  }

  const generatedDescription = useMemo(() => {
    const slideCounts = pins.reduce(
      (acc, pin) => {
        const incr = (key: keyof typeof acc) => ({ ...acc, [key]: acc[key] + 1 });

        const pinType = pin.type;

        switch (true) {
          case InteractivePinTypes.some((t) => pinType === t):
            return incr('interactive');

          case pinType === 'SLIDE':
            return incr('text');

          default:
            return acc;
        }
      },
      { interactive: 0, text: 0, video: 0 }
    );

    const translatedValues = Object.entries(slideCounts)
      .filter(([_type, count]) => count > 0)
      .map(([type, count]) => {
        return `<strong>` + (type === 'video' ? `${count} ` : '') + t(`${type}-slide`, { count }) + `</strong>`;
      });

    return (
      <p>
        <Trans t={t} i18nKey="generated-description" count={pins.length} />
        {translatedValues.length > 0 && (
          <>
            , {t('common:with')}{' '}
            <span dangerouslySetInnerHTML={{ __html: listFormat(t, translatedValues) || '' }}></span>
          </>
        )}
        .
      </p>
    );
  }, [pins, t]);

  function pinOverviewInfo(pin: LessonPin, pinIndex: number): React.ReactElement {
    return (
      <Heading className="lesson-overview-pin-title" as="h4" size="h3">
        Slide {pinIndex + 1} -{' '}
        {capitalize(t('slides:' + PinViewUtils.titleForPinType(pin.item.type, !!pin.videoQuestion)))}
      </Heading>
    );
  }

  const attachments = useMemo(() => {
    const init: { instructions: LessonAttachment[]; worksheets: LessonAttachment[] } = {
      instructions: [],
      worksheets: [],
    };

    if (!lesson.attachments) return init;

    return lesson.attachments.reduce((acc, attachment) => {
      if (attachment.type === 'worksheet') acc.worksheets.push(attachment);
      else acc.instructions.push(attachment);

      return acc;
    }, init);
  }, [lesson.attachments]);

  // We do not have notes yet in the 2.0 version
  // const anyPinHasNotes = pins.some((pin) => Boolean(pin.item.notes && pin.item.notes.text));
  const anyPinHasNotes = false;

  const contextMenuProps: ContextMenuBaseProps = {
    addPin,
    activeLesson,
  };
  const showContextMenu = lesson.privacy !== 'protected' && activeDocument?.type !== 'lessonV2';

  const showTeacherCreditsIndicator = shouldShowTeacherCreditsIndicator(domainLicenseToLicenseStatus(currentLicense));
  const showSaveButton = Language.isMain(LanguageSingleton.get()) && accessTypes.copySearch && !embedLesson;
  return (
    <div className="lesson-overview v2">
      {!embedLesson || (embedLesson && !channel) ? (
        <Heading as="h1" size="h0" className="lesson-overview__title">
          {htmlDecode(lesson.name)}
        </Heading>
      ) : null}
      <FontLoader />

      <div className="lesson-preview v2">
        <div className="lesson-preview__player-container">
          {clientLoaded && (
            <LessonPreviewV2
              lesson={lesson}
              pins={pins}
              preselectedPin={preselectedPin}
              onPinSelected={onPinSelected}
            />
          )}
        </div>
        <div className="lesson-preview__content">
          <div className="lesson-overview-meta">
            <div>
              <MetaDataTagList
                className="lesson-overview-meta-section"
                version="v2"
                metaData={FacetMetaData.fromLesson({ lesson })}
                size="large"
                limit={tagsExpanded ? undefined : 8}
                subjectsLimit={tagsExpanded ? null : SUBJECTS_LIMIT}
                renderOverflow={(overflowCount) => {
                  return (
                    <UnstyledButton
                      className="lesson-overview-meta-tags-show-more"
                      onClick={() => toggleTagsExpanded()}
                    >
                      {t('showMoreTags', { count: overflowCount })}
                    </UnstyledButton>
                  );
                }}
              />

              {channel && (
                <ChannelInfo
                  className="lesson-overview-meta-section"
                  channel={channel}
                  showFollowButton={Boolean(userIsLoggedIn)}
                  channelUrl={channelUrl}
                  lessonId={lesson._id}
                  forceNewTab={!!embedLesson}
                />
              )}

              <div className="lesson-overview-meta-section">
                <div className="lesson-overview-meta__description ">{generatedDescription}</div>
                <LessonDuration durationInMin={lesson.durationInMin} />
              </div>
            </div>

            <div className="lesson-overview-meta-action-section">
              {showTeacherCreditsIndicator && eligibleToTeachWithoutCredits && (
                <Banner
                  paragraph={t('teachingCreditsChannelBanner')}
                  actionElement={
                    <Link
                      external
                      href={
                        LanguageSingleton.is('nl') ? helpdeskArticle('nl', '6737128') : helpdeskArticle('en', '6737128')
                      }
                    >
                      <a target="_blank">{t('teachingCreditsChannelBannerLink')}</a>
                    </Link>
                  }
                />
              )}
              <div className="lesson-overview-meta__buttons lesson-overview-meta-section">
                <div>
                  <ModernButton
                    buttonType="primary"
                    iconStart={<IconLesson />}
                    onClick={(e) => handleTeachClick(false)}
                    onAuxClick={(e) => handleTeachClick(true)}
                  >
                    {teachButtonText()}
                  </ModernButton>
                </div>
                {showTeacherCreditsIndicator && !eligibleToTeachWithoutCredits && (
                  <ClientOnlyModernWrapper SSRFallback={<TeachingCreditsIndicator loading={true} credits={-1} />}>
                    <TeachingCreditsIndicatorFeature />
                  </ClientOnlyModernWrapper>
                )}
                {showSaveButton ? (
                  <SaveButton isLessonSaved={isLessonSaved} handleLessonSave={handleLessonSave} />
                ) : null}
                <ModernButton buttonType="neutral" noFill showStroke onClick={shareLesson} iconStart={<IconShare />}>
                  {t('share')}
                </ModernButton>
              </div>
              <div className="lesson-overview-meta__sub-buttons lesson-overview-meta-section">{flagLessonButton}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="lesson-overview-introduction">
        <div className="lesson-overview-introduction-text">
          {lesson.description && (
            <div>
              <Heading>{t('introduction')}</Heading>
              <div dangerouslySetInnerHTML={{ __html: lesson.description }}></div>
            </div>
          )}

          {lesson.information && (
            <div>
              <Heading>{t('instructions')}</Heading>
              <div dangerouslySetInnerHTML={{ __html: lesson.information }}></div>
            </div>
          )}
        </div>

        {lesson.attachments && lesson.attachments.length > 0 && (
          <div className="lesson-overview-introduction-downloads">
            {attachments.instructions.length > 0 && (
              <div>
                <Heading as="h3" size="h2" style={{ marginTop: 0 }}>
                  {t('instructions')}
                </Heading>
                {attachments.instructions.map((attachment) => (
                  <DownloadAttachment lessonId={lesson._id} attachment={attachment} key={attachment.uploadId} />
                ))}
              </div>
            )}

            {attachments.worksheets.length > 0 && (
              <div>
                <Heading as="h3" size="h2">
                  {t('worksheets')}
                </Heading>
                {attachments.worksheets.map((attachment) => (
                  <DownloadAttachment lessonId={lesson._id} attachment={attachment} key={attachment.uploadId} />
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div>
        <Heading>{t('lesson-parts')}</Heading>
        {clientLoaded && (
          <PinGrid
            pins={pins}
            contextMenuProps={contextMenuProps}
            pinInfo={pinOverviewInfo}
            showContextMenu={showContextMenu}
          />
        )}
      </div>
    </div>
  );
}

function LessonDuration({ durationInMin }: { durationInMin: number | undefined }) {
  const { t } = useTranslation(TRANSLATION_NS);
  if (!durationInMin) return null;
  return (
    <span className="lesson-overview-meta__duration">
      <span className="lesson-overview-meta__icon">
        <img src="/search/public/img/icons/black/clock.png" alt="time-icon" />
      </span>
      {t('lesson-lasts') + ': '}
      <strong>
        {durationInMin} {t('minutes-short')}
      </strong>
    </span>
  );
}

interface SaveButtonProps {
  isLessonSaved: boolean;
  handleLessonSave: () => void;
}

function SaveButton({ isLessonSaved, handleLessonSave }: SaveButtonProps) {
  const { t } = useTranslation(TRANSLATION_NS);

  const buttonProps = isLessonSaved
    ? {
        disabled: true,
        className: 'lesson-saved',
      }
    : {
        onClick: handleLessonSave,
      };

  return (
    <ModernButton buttonType="secondary" iconStart={<IconDownload className="icon" />} {...buttonProps}>
      {isLessonSaved ? (
        <span>{t('saved')}</span>
      ) : (
        <>
          <span>{t('save-for-later')}</span>
        </>
      )}
    </ModernButton>
  );
}

type PinGridProps = {
  pins: TeacherPin[];
  showContextMenu: boolean;
  contextMenuProps: ContextMenuBaseProps;
  pinInfo: (pin: LessonPin, index: number) => React.ReactElement;
};

function PinGrid({ pins }: PinGridProps) {
  return (
    <div className="lesson-overview-pins lesson-overview-pin-grid">
      <div className="pin-flow-40-v2">
        {pins
          .map((pin, index) => {
            return (
              <div className="pin-wrapper" key={pin.id}>
                <PinPreview pin={pin} />
              </div>
            );
          })
          .concat(FlexBoxDummies('pin-wrapper', 4))}
      </div>
    </div>
  );
}

type PinListProps = {
  pins: TeacherPin[];
  contextMenuProps: ContextMenuBaseProps;
  pinInfo: (pin: LessonPin, index: number) => React.ReactElement;
};

// Currenly not in use, left for future reference if we get notes
function PinList({ pins, contextMenuProps, pinInfo }: PinListProps) {
  const { t } = useTranslation(TRANSLATION_NS);
  return (
    <div className="lesson-overview-pins lesson-overview-pin-list">
      {pins.map((pin, index) => {
        return (
          <div key={pin.id} className="lesson-overview-pin-list-item">
            <div className="rel">
              <div className="lesson-overview-pin-container">
                {/* <PinView
                  className="relative lesson-overview-pin"
                  pin={pin}
                  isThumb
                  hideFact={true}
                  contextMenu={() => <PinContextMenu pinId={pin._id} {...contextMenuProps} />}
                  videoPin={videoPin}
                /> */}
              </div>
            </div>
            <div className="lesson-overview-pin-list-item-notes">
              {/* {pinInfo(pin, index)}
              {!!notes ? (
                <div dangerouslySetInnerHTML={{ __html: notes }}></div>
              ) : (
                <p className="lesson-overview-pin-notes-empty">{t('slide-no-notes')}</p>
              )} */}
            </div>
          </div>
        );
      })}
    </div>
  );
}

interface ContextMenuBaseProps {
  addPin?: AddPinToActiveLesson;
  activeLesson?: UserContext['activeLesson'];
}

interface ContextMenuProps extends ContextMenuBaseProps {
  pinId: string;
}

type AddPinState = 'idle' | 'fetching' | 'done' | 'failed';
// Currenly not in use, left for future reference if we get add pin to lesson

function PinContextMenu({ pinId, addPin, activeLesson }: ContextMenuProps) {
  const [pinState, setPinState] = useState<AddPinState>('idle');
  const { t } = useTranslation(TRANSLATION_NS);
  const [pickerOpen, togglePickerOpen] = useToggle(false);

  const add = async () => {
    if (!addPin) return;
    setPinState('fetching');
    const res = await addPin(pinId);
    setPinState(res ? 'done' : 'failed');
  };

  const handlePickerOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    togglePickerOpen(true);
  };

  const handleOnClose = () => {
    togglePickerOpen(false);
  };

  const addButtonDisabledStated: AddPinState[] = ['failed', 'done'];

  // because add to lesson is the only action, for now hide the menu if
  if (!activeLesson) return null;
  return (
    <ContextMenu onClose={handleOnClose}>
      <div style={{ display: pickerOpen ? 'none' : 'block' }}>
        <Button theme={'grey'} onClick={handlePickerOpen} height={'small'}>
          {t('useInMyLesson')}
        </Button>
      </div>
      <div style={{ display: pickerOpen ? 'block' : 'none' }}>
        <Heading size="h3">{htmlDecode(activeLesson.name)}</Heading>
        {activeLesson.firstPin ? <PinView pin={activeLesson.firstPin}></PinView> : <div>{t('lessonHasNoPins')}</div>}
        <div className="flex-align-horizontal">
          <Button onClick={() => add()} disabled={addButtonDisabledStated.includes(pinState)} height={'small'}>
            {pinState === 'done' ? t('useInMyLessonSuccess') : t('useInMyLessonConfirm')}
          </Button>
        </div>
      </div>
    </ContextMenu>
  );
}

export default LessonOverviewV2;

const StyledPinComponentWrapper = styled.div`
  flex: 1;
  margin: ${spacing.size8};
  min-width: 0; //⬆
  min-height: 0; //⬆
`;
